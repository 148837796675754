var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('b-card',[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-secondary my-auto mr-auto"},[_c('i',{class:_vm.$route.meta.iconClass}),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"22px","color":"#7f7f7f"}},[_vm._v(" Fiche entité : ")]),(_vm.ENTITIE)?_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"25px","color":"#343a40"}},[_vm._v(_vm._s(_vm.ENTITIE.libelle))]):_vm._e()]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12"},[_c('modal-actions',{on:{"close":function($event){return _vm.$router.go(
                  _vm.$store.getters['routerHistory/fromCreation'] ? -2 : -1
                )}}})],1)])]),_c('div',[_c('div',{staticClass:"col-12 d-flex p-0 mt-3 mt-md-0 gap"},[_c('div',{staticClass:"shdow col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('p',{staticClass:"h4 text-secondary my-auto mr-auto",staticStyle:{"margin":"5.156px 0 !important"}},[_vm._v(" Informations générales ")]),(_vm.editMode && (_vm.isAdmin || _vm.isSuper))?_c('div',{staticClass:"m-0 d-flex p-0"},[(_vm.editMode)?_c('b-button',{staticClass:"btn-sm",attrs:{"pill":""},on:{"click":_vm.UpdateEntitie}},[_vm._v("Confirmer")]):_vm._e(),(_vm.editMode)?_c('b-button',{staticClass:"btn-sm",staticStyle:{"background-color":"rgb(173, 181, 189)","border-color":"rgb(173, 181, 189)"},attrs:{"pill":""},on:{"click":function($event){(_vm.editMode = !_vm.editMode),
                          (_vm.editCouverturetype = false),
                          (_vm.editModetype = false)}}},[_vm._v("Annuler ")]):_vm._e()],1):(_vm.isAdmin || _vm.isSuper)?_c('button',{staticClass:"text-secondary btn p-0",staticStyle:{"font-size":"30px","color":"#007bffff !important"},attrs:{"href":"#","disabled":_vm.editCouverturetype ? true : false},on:{"click":function($event){(_vm.editMode = !_vm.editMode),
                        _vm.editCouverture == true
                          ? (_vm.editCouverture = !_vm.editCouverture)
                          : '',
                        (_vm.editModetype = true)}}},[_c('i',{staticClass:"mdi mdi-file-document-edit",staticStyle:{"font-size":"30px"},style:({
                        color: _vm.editCouverturetype ? 'gray' : '#007bff',
                      })})]):_vm._e(),_c('hr',{staticClass:"bg-secondary mb-3 mt-1 w-100"})])])]),(!_vm.editMode)?_c('div',[_c('div',{staticClass:"my-3"},[_c('span',[_vm._v("Libellé : ")]),(_vm.ENTITIE.libelle)?_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v(_vm._s(_vm.ENTITIE.libelle))]):_vm._e()])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-form-group',{staticClass:"font-weight-bold",attrs:{"label-cols":"4","label-cols-lg":"3","label":"Libellé :"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.$v.libelle.$error && _vm.$v.libelle.$dirty,
                    },attrs:{"placeholder":"Libellé","type":"text"},model:{value:(_vm.libelle),callback:function ($$v) {_vm.libelle=$$v},expression:"libelle"}},[_vm._v(" > ")]),(_vm.$v.libelle.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.libelle.required ? "Champ obligatoire" : !_vm.$v.libelle.minLength ? `Le champ doit contenir au moins ${_vm.$v.libelle.$params.minLength.min} caractères.` : "")+" ")]):_vm._e()],1)],1)])]),_c('div',{staticClass:"shdow col-md-6"},[_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('p',{staticClass:"h4 text-secondary my-auto mr-auto",staticStyle:{"margin":"5.156px 0px !important"}},[_vm._v(" Couverture ")]),(_vm.editCouverture && (_vm.isAdmin || _vm.isSuper))?_c('div',{staticClass:"m-0 d-flex p-0"},[(_vm.editCouverture)?_c('b-button',{staticClass:"btn-sm",attrs:{"pill":""},on:{"click":_vm.test}},[_vm._v("Confirmer")]):_vm._e(),(_vm.editCouverture)?_c('b-button',{staticClass:"btn-sm",staticStyle:{"background-color":"rgb(173, 181, 189)","border-color":"rgb(173, 181, 189)"},attrs:{"pill":""},on:{"click":function($event){(_vm.editCouverture = !_vm.editCouverture),
                        (_vm.editCouverturetype = false),
                        (_vm.editModetype = false)}}},[_vm._v("Annuler ")]):_vm._e()],1):(_vm.isAdmin || _vm.isSuper)?_c('button',{staticClass:"text-secondary btn p-0",staticStyle:{"font-size":"30px","color":"#007bffff !important"},attrs:{"disabled":_vm.editModetype ? true : false},on:{"click":function($event){(_vm.editCouverture = !_vm.editCouverture),
                      (_vm.editCouverturetype = true)}}},[_c('i',{staticClass:"mdi mdi-file-document-edit",staticStyle:{"font-size":"30px"},style:({
                      color: _vm.editModetype ? 'gray' : '#007bff',
                    })})]):_vm._e(),_c('hr',{staticClass:"bg-secondary mb-3 mt-1 w-100"})])]),(_vm.editCouverture)?_c('Places',{staticClass:"mt-0",attrs:{"testCouverture":_vm.sendingFunction}}):_vm._e(),_c('div',{staticClass:"row m-0 p-0"},_vm._l((_vm.ENTITIE.couvertures),function(covert,index){return _c('div',{key:index,staticClass:"shdow",staticStyle:{"height":"50px","position":"relative","overflow":"visible"}},[_c('p',[_c('country-flag',{directives:[{name:"show",rawName:"v-show",value:(covert.countryCode),expression:"covert.countryCode"}],attrs:{"country":covert.countryCode,"size":"small"}}),_vm._v(" "+_vm._s(covert.country)+" ")],1),_c('div',{staticStyle:{"position":"absolute","top":"-5px","right":"-5px"}},[_c('span',{staticStyle:{"cursor":"pointer"}},[(_vm.editCouverture)?_c('i',{staticClass:"mdi mdi-close-circle text-danger",on:{"click":function($event){return _vm.deleteCouverture(index, covert.id)}}}):_vm._e()])])])}),0)],1)])]),_c('div',{staticClass:"my-4"},[_c('b-tabs',{staticClass:"tab-solid tab-solid-primary mt-3",attrs:{"value":_vm.$store.state.tabs.entityTab},on:{"changed":function($event){_vm.entityTab = _vm.$store.state.tabs.entityTab},"activate-tab":(newTabIndex) =>
              _vm.$store.dispatch('tabs/changeEntityTab', newTabIndex)}},[_c('b-tab',{attrs:{"title":"Organisations"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('entity-organisation',{attrs:{"name":_vm.ENTITIE.libelle,"loading":_vm.loadingData},on:{"showOrganisation":_vm.showOrganisation}})],1)])]),_c('b-tab',{attrs:{"title":"Acteurs"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('entity-actor',{attrs:{"actorEntity":_vm.acteurs}})],1)])])],1)],1)])],1),(_vm.showOrganisationModal)?_c('div',[_c('Modal',{on:{"close":function($event){_vm.showOrganisationModal = false}}},[_c('OrganisationList',{attrs:{"items":_vm.ffff,"sousOrganisations":_vm.sousOrganisations,"libelle":_vm.entityLibelle}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }