<template>
  <div>
    <div>
      <b-card>
        <div class="d-flex">
          <p class="text-secondary my-auto mr-auto">
            <i :class="$route.meta.iconClass"></i>
            <span
              style="font-size: 22px; color: #7f7f7f"
              class="font-weight-light"
            >
              Fiche entité :
            </span>
            <span
              style="font-size: 25px; color: #343a40"
              class="font-weight-bold"
              v-if="ENTITIE"
              >{{ ENTITIE.libelle }}</span
            >
          </p>
          <div class="row mb-3">
            <div class="col-12">
              <modal-actions
                @close="
                  $router.go(
                    $store.getters['routerHistory/fromCreation'] ? -2 : -1
                  )
                "
              />
            </div>
          </div>
        </div>
        <div>
          <div class="col-12 d-flex p-0 mt-3 mt-md-0 gap">
            <div class="shdow col-md-6">
              <div class="row">
                <div class="col-12">
                  <div
                    class="
                      d-flex
                      flex-wrap
                      justify-content-between
                      align-items-center
                    "
                  >
                    <p
                      class="h4 text-secondary my-auto mr-auto"
                      style="margin: 5.156px 0 !important"
                    >
                      Informations générales
                    </p>

                    <div
                      v-if="editMode && (isAdmin || isSuper)"
                      class="m-0 d-flex p-0"
                    >
                      <b-button
                        @click="UpdateEntitie"
                        class="btn-sm"
                        v-if="editMode"
                        pill
                        >Confirmer</b-button
                      >
                      <b-button
                        style="
                          background-color: rgb(173, 181, 189);
                          border-color: rgb(173, 181, 189);
                        "
                        class="btn-sm"
                        @click="
                          (editMode = !editMode),
                            (editCouverturetype = false),
                            (editModetype = false)
                        "
                        v-if="editMode"
                        pill
                        >Annuler
                      </b-button>
                    </div>
                    <button
                      v-else-if="isAdmin || isSuper"
                      href="#"
                      class="text-secondary btn p-0"
                      style="font-size: 30px; color: #007bffff !important"
                      :disabled="editCouverturetype ? true : false"
                      @click="
                        (editMode = !editMode),
                          editCouverture == true
                            ? (editCouverture = !editCouverture)
                            : '',
                          (editModetype = true)
                      "
                    >
                      <i
                        class="mdi mdi-file-document-edit"
                        style="font-size: 30px"
                        :style="{
                          color: editCouverturetype ? 'gray' : '#007bff',
                        }"
                      ></i>
                    </button>
                    <hr class="bg-secondary mb-3 mt-1 w-100" />
                  </div>
                </div>
              </div>
              <div v-if="!editMode">
                <div class="my-3">
                  <span>Libellé : </span>
                  <span class="font-weight-bold mr-3" v-if="ENTITIE.libelle">{{
                    ENTITIE.libelle
                  }}</span>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-12">
                  <b-form-group
                    class="font-weight-bold"
                    label-cols="4"
                    label-cols-lg="3"
                    label="Libellé :"
                  >
                    <b-form-input
                      placeholder="Libellé"
                      v-model="libelle"
                      :class="{
                        'is-invalid': $v.libelle.$error && $v.libelle.$dirty,
                      }"
                      type="text"
                    >
                      >
                    </b-form-input>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.libelle.$dirty"
                    >
                      {{
                        !$v.libelle.required
                          ? "Champ obligatoire"
                          : !$v.libelle.minLength
                          ? `Le champ doit contenir au moins ${$v.libelle.$params.minLength.min} caractères.`
                          : ""
                      }}
                    </span>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="shdow col-md-6">
              <div class="col-12 p-0">
                <div
                  class="
                    d-flex
                    flex-wrap
                    justify-content-between
                    align-items-center
                  "
                >
                  <p
                    class="h4 text-secondary my-auto mr-auto"
                    style="margin: 5.156px 0px !important"
                  >
                    Couverture
                  </p>

                  <div
                    v-if="editCouverture && (isAdmin || isSuper)"
                    class="m-0 d-flex p-0"
                  >
                    <b-button
                      class="btn-sm"
                      @click="test"
                      v-if="editCouverture"
                      pill
                      >Confirmer</b-button
                    >
                    <b-button
                      class="btn-sm"
                      style="
                        background-color: rgb(173, 181, 189);
                        border-color: rgb(173, 181, 189);
                      "
                      @click="
                        (editCouverture = !editCouverture),
                          (editCouverturetype = false),
                          (editModetype = false)
                      "
                      v-if="editCouverture"
                      pill
                      >Annuler
                    </b-button>
                  </div>

                  <button
                    v-else-if="isAdmin || isSuper"
                    class="text-secondary btn p-0"
                    style="font-size: 30px; color: #007bffff !important"
                    :disabled="editModetype ? true : false"
                    @click="
                      (editCouverture = !editCouverture),
                        (editCouverturetype = true)
                    "
                  >
                    <i
                      class="mdi mdi-file-document-edit"
                      style="font-size: 30px"
                      :style="{
                        color: editModetype ? 'gray' : '#007bff',
                      }"
                    ></i>
                  </button>
                  <hr class="bg-secondary mb-3 mt-1 w-100" />
                </div>
              </div>

              <Places
                v-if="editCouverture"
                class="mt-0"
                :testCouverture="sendingFunction"
              />
              <div class="row m-0 p-0">
                <div
                  style="height: 50px; position: relative; overflow: visible"
                  class="shdow"
                  v-for="(covert, index) in ENTITIE.couvertures"
                  :key="index"
                >
                  <p>
                    <country-flag
                      :country="covert.countryCode"
                      size="small"
                      v-show="covert.countryCode"
                    />
                    {{ covert.country }}
                  </p>

                  <div style="position: absolute; top: -5px; right: -5px">
                    <span style="cursor: pointer"
                      ><i
                        v-if="editCouverture"
                        @click="deleteCouverture(index, covert.id)"
                        class="mdi mdi-close-circle text-danger"
                      ></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="my-4">
          <b-tabs
            :value="$store.state.tabs.entityTab"
            @changed="entityTab = $store.state.tabs.entityTab"
            @activate-tab="
              (newTabIndex) =>
                $store.dispatch('tabs/changeEntityTab', newTabIndex)
            "
            class="tab-solid tab-solid-primary mt-3"
          >
            <b-tab title="Organisations">
              <div class="row">
                <div class="col-12">
                  <entity-organisation
                    @showOrganisation="showOrganisation"
                    :name="ENTITIE.libelle"
                    :loading="loadingData"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab title="Acteurs">
              <div class="row">
                <div class="col-12">
                  <entity-actor :actorEntity="acteurs" />
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </div>
    <div v-if="showOrganisationModal">
      <Modal @close="showOrganisationModal = false">
        <OrganisationList
          :items="ffff"
          :sousOrganisations="sousOrganisations"
          :libelle="entityLibelle"
      /></Modal>
    </div>
  </div>
</template>

<script>
import countries from "@/assets/countries/data.json";
import EntityOrganisation from "../components/EntityOrganisation.vue";
import EntityActor from "../components/EntityActor.vue";
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import Places from "@/components/custom/Places.vue";
import CountryFlag from "vue-country-flag";
import Swal from "sweetalert2";
import { minLength, required } from "vuelidate/lib/validators";
import Modal from "@/components/custom/Modal";
import OrganisationList from "../components/OrganisationList.vue";
import {
  USER_URL,
  ORGANISATION_URL,
  COUVERTURE_URL,
  ENTITE_URL,
} from "@/helpers/constURL";

export default {
  components: {
    Places,
    CountryFlag,
    EntityOrganisation,
    EntityActor,
    ModalActions,
    OrganisationList,
    Modal,
  },
  data: () => ({
    entityLibelle: "",
    sousOrganisations: [],
    ffff: null,
    showOrganisationModal: false,
    countries: countries,
    selectedCountries: [],
    editMode: false,
    editCouverture: false,
    loadingData: true,
    activeTab: null,
    organisation: null,
    dataacteur: null,
    editCouverturetype: false,
    editModetype: false,
    selectedOrganisation: [],
    acteur: [],
    selectedData: null,
    required: true,
    libelle: "",
  }),
  validations: {
    libelle: {
      required,
      minLength: minLength(5),
    },
  },
  methods: {
    showOrganisation(sousOrganisations, libelle) {
      if (sousOrganisations.length) {
        this.entityLibelle = libelle;
        this.sousOrganisations = sousOrganisations;
        this.showOrganisationModal = true;
        this.ffff = {
          name: libelle,
          organisation: sousOrganisations,
        };
      }
    },

    test: function () {
      this.selectedData.couvertures.forEach((element) => {
        this.$store.dispatch("couverture/createCouverture", {
          ...element,
          entite: `/api/${ENTITE_URL}/${this.$route.params.id}`,
        });
      });
      this.editCouverture = !this.editCouverture;
      this.editCouverturetype = false;
      this.editModetype = false;
      Swal.fire("La couverture est mise à jour   !", "", "success");
    },
    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    sendingFunction: function (location) {
      if (
        !this.selectedData.couvertures.find(
          (couverture) => couverture.countryCode === location.countryCode
        )
      )
        this.selectedData.couvertures.push(this.clone(location));
      else
        Swal.fire(
          "Impossible d'ajouter une couverture déjà existante !",
          "",
          "error"
        );
    },
    deleteCouverture(index, id) {
      this.selectedData.couvertures.splice(index, 1);
      if (id) this.$store.dispatch("couverture/deleteCouverture", id);
    },
    addCoverture(data) {
      window.console.table(data);
      this.selectedData.couvertures.push(data);
    },
    UpdateEntitie() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.editCouverturetype = false;
        this.editModetype = false;
        var item = {
          id: this.$route.params.id,
          libelle: this.libelle,
          // organisations: this.selectedOrganisation
          // ? this.selectedOrganisation.map((cov) => cov.value)
          // : [],
          // acteurs: this.acteur ? this.acteur.map((cov) => cov.value) : [],
        };
        this.$store.dispatch("entity/updateEntity", item);
        Swal.fire("L'entité est mise à jour !", "", "success");
        this.editMode = !this.editMode;
      }
    },
    deleteItem(index) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("couverture/deleteCouverture", index);
          Swal.fire("Supprimé!", "", "success");
        }
        this.$store.dispatch("entity/updateEntity", this.$route.params.id);
      });
    },
  },
  watch: {
    ENTITIE() {
      this.selectedData = this.ENTITIE;
      this.newEntity = {
        ...this.ENTITIE,
        libelle: this.ENTITIE.libelle ? this.ENTITIE.libelle : "",
        Couverture: this.ENTITIE.couvertures
          ? this.ENTITIE.couvertures.map((cov) => ({
              value: cov["@id"],
              text: cov.country,
            }))
          : "",
      };
      this.libelle = this.ENTITIE.libelle ? this.ENTITIE.libelle : "";
      this.selectedOrganisation = this.ENTITIE.organisations
        ? this.ENTITIE.organisations.map((cov) => ({
            value: cov["@id"],
            text: cov.libelle,
          }))
        : "";
      this.acteur = this.ENTITIE.acteurs
        ? this.ENTITIE.acteurs.map((cov) => ({
            value: cov["@id"],
            text: cov.firstname + " " + cov.lastname,
          }))
        : "";
      this.loadingData = false;
    },
  },

  created() {
    this.$store
      .dispatch("entity/fetchEntitie", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("couverture/fetchAllCouvertures");
    this.$store.dispatch("entity/fetchOrganisation", this.$route.params.id);
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("entity/fetchActeurs", this.$route.params.id);
  },

  computed: {
    ...mapGetters("entity", ["ENTITIE"]),
    ...mapGetters("couverture", ["COUVERTURE"]),
    ...mapGetters("entity", ["ORGANISATION"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("entity", ["ACTEUR"]),
    ...mapGetters(["isAdmin", "isSuper"]),

    acteurs() {
      return this.ACTEUR;
    },
    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/${USER_URL}/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    organisations() {
      return this.ORGANISATIONS.map((org) => ({
        value: `/api/${ORGANISATION_URL}/${org.id}`,
        text: org.libelle,
      }));
    },
    couverture() {
      return this.COUVERTURE.map((couverture) => ({
        value: `/api/${COUVERTURE_URL}/${couverture.id}`,

        text: couverture.country,
      }));
    },
  },
};
</script>

<style>
.gap {
  gap: 2%;
}
</style>
